import React from 'react';
import Layout from '../layout/layout'


const ErrorPage = ()=>{
  return(
    <Layout header={false}>
      <h1>hi error page</h1>
    </Layout>
  )
}

export default ErrorPage;